import { handleSecurityRules } from "../utils/security"

export const permissionCodes = [
    'visible',
    'editable',
    'readonly',
    'hidden',
]

export const visibilityAdapter = ({ defaultVisibility, onChange }) => root => {
    const {rules, combinator} = root
    const mapCombinators = {
        and: 0,
        or: 1,
    }
    const mapValueTypes = {
        value: 0,
        field: 1,
    }
    const conditional = defaultVisibility === 'visible'
        ? 'invisible'
        : 'visible'
    const visibility = [{
        conditional,
        matches: mapCombinators[combinator],
        isMatchesVisible: true,
        conditions: [],
        raw: root,
    }]
    const populateRule = r => {
        const {field, value, operator} = r
        const [valueType, valueExpression] = !value
            ? ['value', '']
            : value
        return {
            field,
            operator,
            type: mapValueTypes[valueType],
            other: valueExpression,
        }
    }
    rules.forEach(rule => {
        if (rule?.rules && rule?.rules.length) {
            const insertRules = rule.rules.reduce((acc, r) => {
                return [
                    ...acc,
                    populateRule(r),
                ]
            }, [])
            visibility.push({
                conditional,
                matches: mapCombinators[rule?.combinator],
                isMatchesVisible: true,
                conditions: insertRules,
            })
        } else {
            visibility[0].conditions.push(
                populateRule(rule)
            )
        }
    })
    onChange(visibility)
}
export const validationAdapter = ({ onChange }) => root => {
    const {rules} = root
    let validations = []
    const populateRule = r => {
        const {value, operator} = r
        const [valueType, valueExpression, valueMessage] = !value
            ? ['value', '', '']
            : value
        return {
            rule: operator,
            type: valueType,
            value: valueExpression,
            customError: valueMessage,
        }
    }
    rules.forEach(rule => {
        if (rule?.rules && rule?.rules.length) {
            const insertRules = rule.rules.reduce((acc, r) => {
                return [
                    ...acc,
                    populateRule(r),
                ]
            }, [])
            validations = [
                ...validations,
                ...insertRules,
            ]
        } else {
            validations = [
                ...validations,
                populateRule(rule),
            ]
        }
    })
    onChange && onChange(validations, root)
}
export const filterAdapter = ({ onChange }) => (root, fields) => {
    let filters = []
    const {rules, combinator} = root
    const mapCombinators = {
        and: 0,
        or: 1,
    }
    const mapValueTypes = {
        value: 'Value',
        field: 'Field',
    }
    const populateRule = r => {
        const {field, value, operator} = r
        const selectedField = fields.find(f => f?.name === field)
        const [valueType, valueExpression, valueFieldType] = !value
            ? ['value', '']
            : value
        return {
            operator,
            field,
            value: valueExpression,
            type: mapValueTypes[valueType],
            ...selectedField?.valueEditorType
                ? { fieldType: selectedField.valueEditorType }
                : {},
            ...valueFieldType
                ? { valueFieldType }
                : {},
        }
    }
    rules.forEach(rule => {
        if (rule?.rules && rule?.rules.length) {
            const insertRules = rule.rules.reduce((acc, r) => {
                return [
                    ...acc,
                    populateRule(r),
                ]
            }, [])
            filters = [
                ...filters,
                ...insertRules,
            ]
        } else {
            filters = [
                ...filters,
                populateRule(rule),
            ]
        }
    })
    onChange({
        filters,
        rawFilters: root,
        matches: mapCombinators[combinator],
    })
}
export const tableFilterAdapter = ({ onChange }) => (root) => {
    const populateRule = r => {
        return {
            ...r,
            value: r.value?.[1] || '',
            fieldType: r.value?.[2] || ''
        }
    }
    const populateRules = (rules) => {
        let newRules = [];
        rules.forEach(rule => {
            if (rule?.rules?.length) {
                newRules = [
                    ...newRules,
                    {
                        ...rule,
                        rules : populateRules(rule.rules),
                    }
                ]
            } else {
                newRules = [
                    ...newRules,
                    populateRule(rule),
                ]
            }
        })
        return newRules;
    }
    onChange({
        filters:{ 
            ...root,
            rules: populateRules(root.rules)
         },
        rawFilters: root
    })
}
export const securityAdapter = (config) => root => {
    const {
        activityList,
        defaultVisibility,
        defaultEditability,
        name,
        key,
        values,
        onChange,
    } = config
    const rawPermission = values?.rawPermission || {}
    const {rules} = root
    let permission = activityList.map(c => {
        const existingActivity = values?.permission.find(p => p?.activityId === c.value) || {}
        const {
            activityId = c.value,
            fieldGroups = [],
            fields = [],
        } = existingActivity
        return {
            activityId,
            fieldGroups,
            fields,
        }
    })

    permission = handleSecurityRules({permission, config, rules});
    onChange({
        permission,
        rawPermission: {
            ...rawPermission,
            ...key
                ? {
                    [name]: {
                        ...rawPermission[name],
                        [key] : {
                            root,
                            ...key === 'visibility'
                                ? { defaultVisibility }
                                : { defaultEditability }
                        },
                    }
                }
                : {}
        },
    })
}
export const requestAdapter = (config) => root => {
    const {
        onChange,
    } = config
    const {rules = []} = root
    let result = []
    const populateRule = r => {
        const [name, type, value, datasetKey, datasetColumn] = !r?.value
            ? ['', 'value', '', '', '']
            : r.value
        return {
            name,
            type,
            value,
            ...datasetKey ? { datasetKey } : {},
            ...datasetColumn ? { datasetColumn } : {},
        }
    }
    if (rules.length > 0) {
        rules.forEach(rule => {
            if (rule?.rules && rule?.rules.length) {
                rule.rules.forEach(r => {
                    if (r?.value) result.push(populateRule(r))
                })
            } else {
                if (rule?.value) result.push(populateRule(rule))
            }
        })
    } else {
        result = []
    }
    onChange({
        result,
        root,
    })
}