const botEndpoints = {       
    trackGenAiUsage({ moduleId, data }){
        return{
            url: `/api/bot/genaiusage?moduleId=${moduleId}`,
            method: 'POST',
            data,
        }
    },
}

export default botEndpoints